<template>
    <div class="app-footer">
        <!--begin::Copyright-->
        <div class="order-2 order-xl-1">
            <div class="text-muted">
                <small>&copy; {{ currentYear }} <strong>HOSPEDIUM</strong> & <strong>WEJYC</strong>. Todos los derechos reservados.
                </small>
            </div>
        </div>
        <!--end::Copyright-->
        <!--begin::Menu-->
        <ul class="menu order-1">
            <li class="menu-item">
                <a href="https://hospedium.com/aviso-legal/" target="_blank" class="menu-link text-muted">Aviso legal</a>
            </li>
            <li class="menu-item">
                <a href="https://hospedium.com/politica-de-privacidad/" target="_blank" class="menu-link text-muted">Política de Privacidad</a>
            </li>
            <li class="menu-item">
                <a href="https://hospedium.com/cookies/" target="_blank" class="menu-link text-muted">Política
                    de Cookies</a>
            </li>
        </ul>
        <!--end::Menu-->
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    name: "MainFooter",
    setup() {
        // Definir el año actual como un estado reactivo
        const currentYear = ref(new Date().getFullYear());

        return {
            currentYear
        };
    },
};
</script>

<style scoped>
.app-footer {
    margin-left: 200px;
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: space-between;
    padding: 10px 40px 20px 20px;
}

.menu {
    list-style: none;
    display: flex;
    padding-left: 0;
    gap: 10px;
    margin-bottom: 0;

}

.menu-link {
    padding: 0;
    font-size: 14px;
}

@media screen and (max-width: 1200px) {
    .app-footer {
        flex-direction: column;
        gap: 10px;
    }
}

@media screen and (max-width: 992px) {
    .app-footer {
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .menu {
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }
}
</style>