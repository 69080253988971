import apiService from "@/services/api.service";
import store from "@/store";

const AuthService = {
	async login(data) {
		try {
			const response = await apiService.post("/auth/login/", data);

			if (response.data.access) {
				localStorage.setItem("auth_token", JSON.stringify(response.data));

				await store.dispatch("user/fetchUserCurrentAccount");
				await store.dispatch("user/fetchUserRole");
				await store.dispatch("user/fetchUser");

				console.log(store);
				await store.dispatch("auth/finishAuthChecking");
				return response;
			}
		} catch (error) {
			console.log("ERROR", error);
			localStorage.clear();
			// Handle errors appropriately
			return {
				status: error.response ? error.response.status : 500,
				data: error.response ? error.response.data : "An error occurred",
			};
		}
	},

	logout() {
		localStorage.removeItem("auth_token");
		localStorage.removeItem("account");
	},

	isLoggedIn() {
		const auth_token = JSON.parse(localStorage.getItem("auth_token"));
		return auth_token && auth_token.access;
	},

	getAuthToken() {
		return JSON.parse(localStorage.getItem("auth_token"));
	},

	getLocalRefreshToken() {
		const auth = JSON.parse(localStorage.getItem("auth_token"));
		return auth?.refresh;
	},

	getLocalAccessToken() {
		const auth = JSON.parse(localStorage.getItem("auth_token"));
		return auth?.access;
	},

	updateLocalAccessToken(token) {
		let auth_token = JSON.parse(localStorage.getItem("auth_token"));
		auth_token.access = token;
		localStorage.setItem("auth_token", JSON.stringify(auth_token));
	},
};

export default AuthService;
