// auth.js
export default [
    {
        path: "/login",
        name: "LoginView",
        component: () => import("@/views/Auth/Login.vue"),
    },

    // Otras rutas de autenticación
];
