import { createApp } from "vue";
import App from "@/App.vue";
import i18n from "./i18n";
import store from "@/store/index";
import router from "@/router/index";
import SwalPlugin from "@/plugins/sweetalert2";
import BootstrapPlugin from "@/plugins/bootstrap";

// Import Styles & Scripts files
require("@/index");

import setupInterceptors from "@/services/setupInterceptors";
setupInterceptors(store);

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import { all } from "@awesome.me/kit-fdcc4c2fb2/icons";
/* add icons to the library */
library.add(...all);

// Import Vue3EasyDataTable
import Vue3EasyDataTable from "vue3-easy-data-table";

// Import QuillEditor
import { QuillEditor } from "@vueup/vue-quill";

// Maz-UI Components
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import MazSelect from "maz-ui/components/MazSelect";
import MazInput from "maz-ui/components/MazInput";
import MazIcon from "maz-ui/components/MazIcon";
import MazInputPrice from "maz-ui/components/MazInputPrice";
import MazPicker from "maz-ui/components/MazPicker";
import MazCheckbox from "maz-ui/components/MazCheckbox";
import MazInputTags from "maz-ui/components/MazInputTags";
import MazTextarea from "maz-ui/components/MazTextarea";
import MazRadioButtons from "maz-ui/components/MazRadioButtons";
import MazRadio from "maz-ui/components/MazRadio";
import MazInputNumber from "maz-ui/components/MazInputNumber";
import MazTable from "maz-ui/components/MazTable";
import MazDropzone from "maz-ui/components/MazDropzone";

// Register global components
const components = {
	QuillEditor,
	MazPhoneNumberInput,
	MazSelect,
	MazInput,
	MazIcon,
	MazInputPrice,
	MazInputNumber,
	MazRadio,
	MazRadioButtons,
	MazPicker,
	MazCheckbox,
	MazInputTags,
	MazTextarea,
	MazTable,
	MazDropzone,
	EasyDataTable: Vue3EasyDataTable,
	"font-awesome-icon": FontAwesomeIcon,
};

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(SwalPlugin);
app.use(BootstrapPlugin);

Object.entries(components).forEach(([name, component]) => {
	app.component(name, component);
});

app.mount("#app");
