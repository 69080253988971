import apiService from "@/services/api.service";
import AccountService from "@/services/account.service";

const UserService = {
	// Function to retrieve account data by UUID
	async getCurrentUser() {
		return await apiService
			.get("/users/me")
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	},

	async getCurrentUserAccountRole() {
		let account = JSON.parse(localStorage.getItem("account"));
		if (!account) {
			let user_accounts = await AccountService.getCurrentUserAccounts();
			account = user_accounts[0];
		}

		return await apiService
			.get("/users/me/account/role/" + account.uuid)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	},
};

export default UserService;
