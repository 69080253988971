<template>
    <nav id="navbar" class="navbar bg-light">
        <div class="container-fluid">
            <div class="d-flex align-items-center">
                <!-- Icono de menú -->
                <button class="navbar-toggler me-3 d-lg-none" type="button" @click="toggleSidebar" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fa-regular fa-bars"></i>
                </button>

                <!-- Logo -->
                <a class="navbar-brand d-flex align-items-center" href="/">
                    <img v-if="account.logo" src="@/assets/img/logo-arthur-panel.png" alt="Logo" height="20" />
                </a>
            </div>

            <!-- Iconos del lado derecho -->
            <div class="d-flex align-items-center gap-4">
                <!-- <div class="me-3"><small>Cuenta: {{ account.name }}</small></div> -->
                <div class="btn-nav-icon"><font-awesome-icon :icon="['fas', 'circle-question']" /></div>
                <div class="btn-nav-icon"><font-awesome-icon :icon="['fas', 'bell']" /></div>
                <div class="avatar position-relative" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
                    <img class="logo" v-if="account.logo" :src="account.logo" alt="Logo" height="30" />
                    <span v-else>{{ account.name }}</span>
                    <img v-if="user.avatar" :src="user.avatar.image_file" class="profile" />
                    <span v-else>{{ getFirstLetter(user.first_name) }}</span>

                    <!-- Dropdown que se muestra al pasar el ratón -->
                    <div v-if="showDropdown" class="dropdown-menu-custom">
                        <div class="dropdown-header">
                            <div class="avatar">
                                <img v-if="user.avatar" :src="user.avatar.image_file" />
                                <span v-else>{{ getFirstLetter(user.first_name) }}</span>
                            </div>
                            <div>
                                <div class="name">{{ user.first_name + ' ' + user.last_name }}</div>
                                <div class="email">{{ user.email }}</div>
                            </div>
                        </div>
                        <div class="dropdown-body">
                            <div class="dropdown-item">Mi perfil</div>
                        </div>
                        <div class="dropdown-footer">
                            <div class="dropdown-item">Ajustes de cuenta</div>
                            <div class="dropdown-item">Cambiar de cuenta</div>
                            <div class="dropdown-item" href="#" @click="logOut">Cerrar sesión</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import EventBus from "@/common/EventBus";

export default {
    name: "MainHeader",
    data() {
        return {
            showDropdown: false,
            showSubDropdown: false,
            loading: true,
            isFullScreen: false,
            isMenuMinified: false,
        };
    },
    computed: {
        account() {
            console.log(this.$store.state.user.account, "account")
            return this.$store.state.user.account;
        },
        user() {
            console.log(this.$store.state.user, "user")
            return this.$store.state.user.data;
        }
    },
    methods: {
        async start() {
            this.loading = true;
            this.loading = false;
        },

        getFirstLetter(name) {
            return name ? name.charAt(0).toUpperCase() : '';
        },

        getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },

        selectAccount(account) {
            // Actualizar el estado en Vuex Store
            this.$store.dispatch('user/selectAccount', account).then(() => {
                this.$router.push("/").catch(err => {
                    // Manejar el error si la misma ruta es rechazada
                    console.log(err)
                }).finally(() => {
                    // Recargar la página después de redirigir
                    window.location.reload();
                });
            });

        },

        toggleSidebar() {
            const sidebar = document.getElementById("sidebar");

            // Verifica el tamaño de la ventana
            if (window.innerWidth < 992) {
                // Si la pantalla es menor de 992px, alterna la clase 'show'
                sidebar.classList.toggle("show");
            } else {
                // Si la pantalla es mayor o igual a 992px, alterna la clase 'minified'
                sidebar.classList.toggle("minified");
            }
        },



        logOut() {
            this.$store.dispatch("user/logout");
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
    },
    created() {
        this.start();

        // Event listener para detectar cambios en el tamaño de la ventana
        window.addEventListener('resize', function () {
            const sidebar = document.getElementById("sidebar");

            // Aplica las clases según el tamaño de la ventana
            if (window.innerWidth < 992) {
                sidebar.classList.remove("minified");
                sidebar.classList.remove("show");
            } else {
                sidebar.classList.add("show");
            }
        });
    },
    mounted() {
        EventBus.on("logout", () => {
            this.logOut();
        });
    },
    beforeUnmount() {
        EventBus.remove("logout");
    },
};
</script>

<style scoped>
.navbar {
    background-color: #f8f9fa;
    padding: 5px 10px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 70px;
    z-index: 10;
}

.navbar-brand {
    color: var(--dark-blue-arthur);
}

.position-relative {
    display: inline-block;
}

.badge {
    font-size: 0.75rem;
    padding: 0.25em 0.5em;
}

.avatar {
    width: 160px;
    height: 50px;
    background-color: white;
    color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eee;
    padding: 5px;
    gap: 15px
}

.avatar .logo {
    width: 105px;
}

.avatar .profile {
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

.btn-nav-icon {
    font-size: 22px;
    color: var(--dark-blue-arthur);
}

.dropdown-menu-custom {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 220px;
    background-color: white;
    border: 0;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    color: var(--bs-text-gray);
}

.dropdown-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-bottom: 1px solid #F9F9F9;
    gap: 20px;
}

.dropdown-header .avatar {
    width: 100%;
    border: 0;
    align-items: center;
    justify-content: center;
}

.dropdown-header .avatar img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
}

.dropdown-header .name {
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

.dropdown-header .email {
    font-size: 10px;
}

.dropdown-body {
    padding: 10px 20px;
    border-bottom: 1px solid #F9F9F9;
}

.dropdown-footer {
    padding: 10px 20px;
}

.dropdown-item {
    color: var(--bs-text-gray);
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
    margin-bottom: 5px;
}

.dropdown-item:hover {
    color: var(--primary-color);
    background-color: #F1F1F4;
    border-radius: 8px;
}

.navbar-toggler {
    background-color: white;
    border: 0;
    font-size: 12px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 992px) {

    .navbar {
        height: 60px;
        padding: 5px 10px;
    }
}
</style>