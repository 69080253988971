<template>
    <div class="d-flex flex-column flex-root app-root" id="app_root">
        <div class="app-page flex-column flex-column-fluid" style="min-height: 100dvh;" id="app_page">
            <MainHeader v-if="loggedIn && !isLoginPage" />

            <div class="app-wrapper d-flex" id="app_wrapper" style="min-height: 100dvh;">
                <MainSidebar v-if="loggedIn && !isLoginPage" />
                <router-view />
            </div>
            <MainFooter v-if="loggedIn && !isLoginPage" />
        </div>
    </div>
</template>

<script>
import EventBus from "./common/EventBus";
import MainHeader from "@/components/headers/MainHeader.vue";
import MainFooter from "@/components/footers/MainFooter.vue";
import MainSidebar from "@/components/Sidebars/MainSidebar.vue";

export default {
    name: "App",
    components: { MainHeader, MainFooter, MainSidebar },
    data() {
        return {
            account: JSON.parse(localStorage.getItem("account")) || null, // Obtener la cuenta del localStorage
        };
    },
    watch: {
        // Observa cambios en `account` para actualizar el título
        account: {
            handler(newAccount) {
                this.updatePageTitle(newAccount);
            },
            immediate: true, // Ejecutar inmediatamente al cargar la página
        },
    },
    methods: {
        setTheme() {
            var defaultThemeMode = "light";
            var themeMode;
            if (document.documentElement) {
                if (
                    document.documentElement.hasAttribute("data-bs-theme-mode")
                ) {
                    themeMode =
                        document.documentElement.getAttribute(
                            "data-bs-theme-mode"
                        );
                } else {
                    if (localStorage.getItem("data-bs-theme") !== null) {
                        themeMode = localStorage.getItem("data-bs-theme");
                    } else {
                        themeMode = defaultThemeMode;
                    }
                }
                if (themeMode === "system") {
                    themeMode = window.matchMedia(
                        "(prefers-color-scheme: dark)"
                    ).matches
                        ? "dark"
                        : "light";
                }
                document.documentElement.setAttribute(
                    "data-bs-theme",
                    themeMode
                );
            }
        },

        updatePageTitle(account) {
            // Cambiar el título de la página en función de la cuenta
            if (account && account.name) {
                document.title = `${account.name} | Admin Panel`;
            } else {
                // Título predeterminado si no hay cuenta
                document.title = 'Arthur | Admin Panel';
            }
        },

        updateFavicon(account) {
            // Ruta del favicon predeterminado usando require para hacer referencia a la imagen en src/assets
            const defaultFavicon = require('@/assets/img/favicon_default.png');
            const faviconURL = account && account.favicon ? account.favicon : defaultFavicon;

            // Encontrar el elemento <link rel="icon"> existente, o crearlo si no existe
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            // Establecer la nueva URL del favicon
            console.log(faviconURL)
            link.href = faviconURL;
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.user.status.loggedIn;
        },
        isLoginPage() {
            return this.$route.path === '/login';
        },
    },
    mounted() {
        EventBus.on("logout", () => {
            this.logOut();
        });
        this.updatePageTitle(this.account);
        this.updateFavicon(this.account);
    },
    beforeUnmount() {
        EventBus.remove("logout");
    },
};
</script>

<style></style>
