import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL; // Usará la variable de entorno

// Crea una instancia de Axios con configuraciones personalizadas
const apiService = axios.create({
	baseURL: API_URL, // Reemplaza con la URL de tu API
	timeout: 5000, // Tiempo de espera para las solicitudes en milisegundos
	headers: {
		"Content-Type": "application/json",
		// Agrega cualquier encabezado personalizado que necesites aquí
	},
});

export default apiService;
