import apiService from "./api.service";
import AuthService from "./auth.service";
import router from "../router";

const setup = (store) => {
    apiService.interceptors.request.use(
        (config) => {
            const auth_token = AuthService.getAuthToken();
            if (auth_token && auth_token.access) {
                config.headers["Authorization"] = "Bearer " + auth_token.access; // for Spring Boot back-end
                // config.headers["x-access-token"] = token; // for Node.js Express back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    apiService.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            console.log(err);
            if (
                err.response.status === 401 &&
                originalConfig.url === "/auth/refresh/"
            ) {
                console.log(originalConfig.url);
                store.dispatch("auth/logout");
                router.push("/login");
                return Promise.reject(err);
            }

            if (originalConfig.url !== "/auth/login/" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await apiService.post("/auth/refresh/", {
                            refresh: AuthService.getLocalRefreshToken(),
                        });
                        const accessToken = rs.data.access;

                        console.log(rs.status);
                        if (rs.status === 401) {
                            store.dispatch("auth/logout");
                        }

                        store.dispatch("auth/refreshToken", accessToken);
                        AuthService.updateLocalAccessToken(accessToken);

                        return apiService(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;
