export default [
	{
		path: "/home",
		name: "DashboardView",
		component: () => import("@/views/App/Dashboard/Dashboard.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/profile",
		name: "Profile",
		component: () => import("@/views/App/Profile/Profile.vue"),
		meta: { requiresAuth: true },
	},
];
