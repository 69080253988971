import AuthService from "../services/auth.service";
import createPersistedState from "vuex-persistedstate";

const user = JSON.parse(localStorage.getItem("auth_token"));
const initialState = user ? { status: { loggedIn: true }, user, authChecking: true } : { status: { loggedIn: false }, user: null, authChecking: true };

export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		login({ commit }, user) {
			return AuthService.login(user).then(
				(user) => {
					commit("loginSuccess", user);
					return Promise.resolve(user);
				},
				(error) => {
					commit("loginFailure");
					return Promise.reject(error);
				}
			);
		},
		logout({ commit }) {
			AuthService.logout();
			commit("logout");
		},
		register({ commit }, user) {
			return AuthService.register(user).then(
				(response) => {
					commit("registerSuccess");
					return Promise.resolve(response.data);
				},
				(error) => {
					commit("registerFailure");
					return Promise.reject(error);
				}
			);
		},
		refreshToken({ commit }, accessToken) {
			commit("refreshToken", accessToken);
		},
		finishAuthChecking({ commit }) {
			commit("setAuthChecking", false);
		},
	},
	mutations: {
		loginSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
		},
		loginFailure(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		logout(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		registerSuccess(state) {
			state.status.loggedIn = false;
		},
		registerFailure(state) {
			state.status.loggedIn = false;
		},
		refreshToken(state, accessToken) {
			state.status.loggedIn = true;
			state.user = { ...state.user, accessToken: accessToken };
		},
		setAuthChecking(state, checking) {
			state.authChecking = checking;
		},
	},
	plugins: [
		createPersistedState({
			// List the state properties you want to persist
			paths: ["auth"],
		}),
	],
};
