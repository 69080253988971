// src/i18n.js
import { createI18n } from "vue-i18n";
import es from "./locales/es";

// Definir los mensajes en los distintos idiomas
const messages = {
	es,
};

// Crear una instancia de vue-i18n
const i18n = createI18n({
	locale: "es", // Idioma predeterminado
	fallbackLocale: "es", // Idioma de reserva si no se encuentra la traducción
	messages, // Mensajes de traducción
});

export default i18n;
