// Importing the user service for API calls
import UserService from "@/services/user.service.js";

// Importing the account service for API calls
import AccountService from "@/services/account.service.js";

import createPersistedState from "vuex-persistedstate";

// Initial state setup for the user module
const initialState = {
	status: { loggedIn: false },
	data: null,
	role: null,
	account: null,
};

const mutations = {
	// Mutation to set the user's role in the state
	SET_ROLE(state, role) {
		state.role = role;
	},
	// Mutation to set the user's data and update the logged-in status
	SET_USER(state, user) {
		state.data = user;
		state.status.loggedIn = true;
	},
	// Mutation to set the current account of the user
	SET_ACCOUNT(state, account) {
		state.account = account;
	},
	LOGOUT(state) {
		state.status.loggedIn = false;
		state.user = null;
		state.account = null;
	},
};

const actions = {
	// Action to fetch and commit the user's role
	fetchUserRole({ commit }) {
		return UserService.getCurrentUserAccountRole().then(
			(response) => {
				commit("SET_ROLE", response.data.role);
				return Promise.resolve(response.data.role);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	},

	// Action to fetch and commit the user's data
	fetchUser({ commit }) {
		return UserService.getCurrentUser().then(
			(response) => {
				commit("SET_USER", response.data);
				return Promise.resolve(response.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	},

	// Action to fetch and commit the user's current account
	async fetchUserCurrentAccount({ commit }) {
		return await AccountService.setInitialLocalAccount().then(
			(response) => {
				commit("SET_ACCOUNT", response);
				return Promise.resolve(response);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	},

	selectAccount({ commit }, account) {
		// Guardar la cuenta seleccionada en localStorage
		localStorage.setItem("account", JSON.stringify(account));

		// Actualizar el estado en Vuex
		commit("SET_ACCOUNT", account);
	},

	logout({ commit }) {
		commit("LOGOUT");
	},
};

const getters = {
	// Getter to access the user's role
	userRole: (state) => state.role,

	// Getter to access the current user's data
	currentUser: (state) => state.user,

	// Getter to access the current account of the user
	currentAccount: (state) => state.account,
};

export const user = {
	namespaced: true,
	state: initialState,
	mutations,
	actions,
	getters,
	plugins: [
		createPersistedState({
			// List the state properties you want to persist
			paths: ["status", "data", "role", "account"],
		}),
	],
};
