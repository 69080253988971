export default [
	{
		path: "/accommodations/",
		name: "ListAccommodations",
		component: () => import("@/views/App/Accommodations/ListAccommodations.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/accommodations/view/info/:accommodationId",
		name: "InfoAccommodation",
		component: () => import("@/views/App/Accommodations/single/InfoAccommodation.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/accommodations/view/images/:accommodationId",
		name: "ImagesAccommodation",
		component: () => import("@/views/App/Accommodations/single/ImagesAccommodation.vue"),
		meta: { requiresAuth: true },
	},

	// {
	// 	path: "/accommodations/view/rooms/:accommodationId",
	// 	name: "RoomsAccommodation",
	// 	component: () => import("@/views/App/Accommodations/single/RoomsAccommodation.vue"),
	// 	meta: { requiresAuth: true },
	// },
];
